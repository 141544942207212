import React from "react";
import { connect } from "react-redux";
import { resetFloor } from "../actions/floorActions";
import { setResetTimer, setClearTimer, setRevertResetTimer } from "../actions/timerActions";
import { useSpring, animated } from "react-spring";
import * as easings from 'd3-ease'
import ArrowLeft from "../assets/arrow-left.svg";
import Reset from "../assets/reset.svg";

const ResetButtons = props => {

    const startOver = () => {
        props.setClearTimer()
        props.resetFloor()
    }

    const reset = () => {
        document.body.classList.remove("background__black");
        document.body.classList.add("background__blue")
        props.setResetTimer()
    }

    const moveDown = useSpring({
        top: props.timerDone ? '325px' : '0px',
        config: { duration: 1000, easing: easings.easeCubicInOut }
    });

    const btnFadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 300 },
        delay: 1500
    });

    return (
        <animated.div className="elevator__button-container" style={moveDown}>
            <animated.button className="elevator__button" onClick={startOver} style={btnFadeIn}>
                <div className="elevator__button-inner-container">
                    <img className="elevator__button-icon" src={ArrowLeft} alt="" />
                    Start Over
            </div>
            </animated.button>
            {props.startTimer
                ? (
                    <button className="elevator__button" onClick={reset}>
                        <div className="elevator__button-inner-container">
                            <img className="elevator__button-icon" src={Reset} alt="" />
                            Reset Timer
                    </div>
                    </button>
                )
                : null
            }
        </animated.div>
    )
}
const mapStateToProps = state => ({
    startTimer: state.timer.startTimer,
    timerDone: state.timer.timerDone
});

const mapDispatchToProps = dispatch => ({
    resetFloor: () => dispatch(resetFloor()),
    setResetTimer: () => dispatch(setResetTimer()),
    setClearTimer: () => dispatch(setClearTimer())
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetButtons);