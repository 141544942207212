import React from "react";
import { useSpring, animated } from "react-spring";

const SVGBackground = props => {

    const radius = useSpring({
        from: { r: 10 },
        to: { r: 1000 },
        config: { duration: 600 }
    });

    return (
        <React.Fragment>
            <svg viewBox="0 0 400 445" className="svg-test">
                <animated.circle
                    r="20"
                    cx="200"
                    cy="210"
                    fill="#5280FF"
                    style={radius}
                />
            </svg>
        </React.Fragment>
    )
}

export default SVGBackground;