import { TIMER } from "../actions/types";

const initialState = {
  counterDone: false,
  timerDone: false,
  startTimer: false,
  resetTimer: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TIMER.CLEAR:
      return {
        ...state,
        counterDone: false,
        timerDone: false,
        startTimer: false,
        resetTimer: false
      }
    case TIMER.COUNTER_DONE:
      return {
        ...state,
        counterDone: action.payload
      };
    case TIMER.TIMER_DONE:
      return {
        ...state,
        timerDone: action.payload
      }
    case TIMER.START_TIMER:
      return {
        ...state,
        startTimer: action.payload
      }
    case TIMER.RESET:
      return {
        ...state,
        counterDone: false,
        timerDone: false,
        startTimer: false,
        resetTimer: true
      }
    case TIMER.REVERT_RESET:
      return {
        ...state,
        resetTimer: false
      }
    default:
      return state;
  }
}