import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setStartTimer, setTimerDone, setRevertResetTimer } from "../actions/timerActions";
import { countdown } from "../utils";
import { useSpring, animated } from "react-spring";
import Counter from "./Counter";
import Ring from "./Ring";
import PropTypes from "prop-types";

const propTypes = {
    time: PropTypes.number
};

const Timer = props => {
    const [displayGo, setDisplayGo] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(props.time);
    const timerID = useRef();
    const setTimerID = value => timerID.current = value;
    const timerEndID = useRef();
    const setTimerEndID = value => timerEndID.current = value;

    // ON DISMOUNT
    useEffect(() => {
        return () => {
            clearInterval(timerID.current);
            clearInterval(timerEndID.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // COUNTER DONE
    useEffect(() => {
        if (props.counterDone) {
            setDisplayGo(true);
            document.body.classList.remove("background__blue");
            document.body.classList.add("background__black");
            //if (props.resetTimer) props.setRevertResetTimer()
            if (timerID.current) clearInterval(timerID.current);
            if (timerEndID.current) clearInterval(timerEndID.current);
            setTimeout(() => {
                setDisplayGo(false);
                props.setStartTimer(true)
                startClock();
            }, 1000)
        } else {
            props.setStartTimer(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.counterDone])

    // TIMER DONE
    useEffect(() => {
        if (timeRemaining === 0) {
            const id = setTimeout(() => {
                clearInterval(id);
                props.setTimerDone(true);
            }, 1000)
            setTimerEndID(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeRemaining])

    // RESET
    useEffect(() => {
        if (props.resetTimer) {
            clearInterval(timerID.current);
            setTimeRemaining(props.time);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetTimer])

    const startClock = () => {
        const id = countdown({
            from: props.time,
            to: 0,
            interval: 1000
        }, num => setTimeRemaining(num));
        setTimerID(id);
    }

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'scale(0.7)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { duration: 300 },
        delay: 1500
    });

    const startTimerFadeIn = useSpring({
        opacity: props.startTimer || props.resetTimer ? 1 : 0,
        transform: props.startTimer || props.resetTimer ? 'scale(1)' : 'scale(0.7)',
        config: { duration: 300 },
        delay: 300
    });

    return (
        <div className="timer__container">
            <Counter />
            {props.startTimer
                ? (
                    <Ring time={props.time} />
                )
                : (
                    <React.Fragment>
                        <div>
                            <animated.svg viewBox="0 0 400 400" style={fadeIn}>
                                <circle
                                    r="190"
                                    cx="200"
                                    cy="200"
                                    fill="#5280FF"
                                    stroke="#FFFFFF"
                                    strokeWidth="10"
                                    strokeOpacity="0.1"
                                />
                                <circle
                                    r="190"
                                    cx="200"
                                    cy="200"
                                    fill="#5280FF"
                                />
                            </animated.svg>
                        </div>
                    </React.Fragment>
                )
            }
            <article className="timer__display">
                <div className="timer__display-container">
                    {displayGo
                        ? (
                            <div className="timer__display-time">GO!</div>
                        )
                        : (
                            <animated.div style={startTimerFadeIn}>
                                <animated.div className="timer__display-time" style={fadeIn}>
                                    {timeRemaining}
                                </animated.div>
                                <br />
                                <animated.div className="timer__display-seconds" style={fadeIn}>
                                    seconds
                                </animated.div>
                            </animated.div>
                        )
                    }
                </div>
            </article>
        </div>
    )
}

Timer.propTypes = propTypes;

const mapStateToProps = state => ({
    counterDone: state.timer.counterDone,
    resetTimer: state.timer.resetTimer,
    startTimer: state.timer.startTimer
});

const mapDispatchToProps = dispatch => ({
    setStartTimer: value => dispatch(setStartTimer(value)),
    setTimerDone: value => dispatch(setTimerDone(value)),
    setRevertResetTimer: () => dispatch(setRevertResetTimer())
})

export default connect(mapStateToProps, mapDispatchToProps)(Timer);