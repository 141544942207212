import { FLOOR } from "./types";

export const resetFloor = () => ({
    type: FLOOR.RESET
})

export const setFloor = payload => ({
    type: FLOOR.SET_FLOOR,
    payload
})

export const setTime = payload => ({
    type: FLOOR.SET_TIME,
    payload
})