import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setCounterDone } from "../actions/timerActions";
import { useSpring, animated } from "react-spring";
import { countdown } from "../utils"

const Counter = props => {
    const initValue = 3;
    const [count, setCount] = useState(initValue);
    const counterID = useRef();
    const setCounterID = value => counterID.current = value;

    // ON MOUNT AND DISMOUNT
    useEffect(() => {
        let id = setTimeout(() => {
            clearTimeout(id);
            startCounter();
        }, 2500);
        return () => {
            if (id) clearTimeout(id)
            clearInterval(counterID.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // WHEN COUNT IS DONE FIRE EVENT
    useEffect(() => {
        if (count === 0) {
            props.setCounterDone(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    // RESET
    useEffect(() => {
        if (props.resetTimer) {
            clearInterval(counterID.current);
            setCount(initValue);
            startCounter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetTimer])

    const startCounter = () => {
        const id = countdown({
            from: initValue,
            to: 0,
            interval: 1500
        }, num => setCount(num));
        setCounterID(id)
    }

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'scale(0.7)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { duration: 300 },
        delay: 2500
    });

    return (
        <React.Fragment>
            {count !== 0
                ? (
                    <animated.div className="counter__container" style={fadeIn}>
                        <div className="counter__container-inner">
                            <div className="counter__background">
                                <div className="counter__count-container">
                                    <div className="counter__count">{count}</div>
                                </div>
                            </div>
                        </div>
                    </animated.div>
                )
                : null
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    resetTimer: state.timer.resetTimer
});

const mapDispatchToProps = dispatch => ({
    setCounterDone: value => dispatch(setCounterDone(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Counter);