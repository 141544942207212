import React from "react";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";

const propTypes = {
    time: PropTypes.number.isRequired
};

const Ring = props => {

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 300 },
        delay: 300
    });


    const offset = useSpring({
        from: { strokeDashoffset: 0 },
        to: { strokeDashoffset: (2 * Math.PI * 190) * -1 },
        config: { duration: props.time * 1000 }
    });

    return (
        <animated.div style={fadeIn}>
            <div className="timer__background">
                <svg viewBox="0 0 400 400">
                    <circle
                        r="190"
                        cx="200"
                        cy="200"
                        fill="#5280FF"
                        stroke="#FFFFFF"
                        strokeWidth="10"
                    />
                    <animated.circle
                        r="190"
                        cx="200"
                        cy="200"
                        fill="transparent"
                        stroke="#628DFF"
                        strokeWidth="11"
                        strokeDasharray={2 * Math.PI * 190}
                        strokeDashoffset="0"
                        transform="rotate(-90 200 200)"
                        style={offset}
                    />
                </svg>
            </div>
        </animated.div>
    )
}

Ring.propTypes = propTypes;

export default Ring;