export const FLOOR = {
    RESET: "FLOOR_RESET",
    SET_FLOOR: "FLOOR_SET_FLOOR",
    SET_TIME: "FLOOR_SET_TIME"
}

export const TIMER = {
    COUNTER_DONE: "TIMER_COUNTER_DONE",
    TIMER_DONE: "TIMER_TIMER_DONE",
    START_TIMER: "TIMER_START_TIMER",
    RESET: "TIMER_RESET",
    CLEAR: "TIMER_CLEAR",
    REVERT_RESET: "TIMER_REVERT_RESET"
}