import { TIMER } from "./types";

export const setCounterDone = payload => ({
  type: TIMER.COUNTER_DONE,
  payload
})

export const setTimerDone = payload => ({
  type: TIMER.TIMER_DONE,
  payload
});

export const setStartTimer = payload => ({
  type: TIMER.START_TIMER,
  payload
});

export const setResetTimer = () => ({
  type: TIMER.RESET
});

export const setClearTimer = () => ({
  type: TIMER.CLEAR
})

export const setRevertResetTimer = () => ({
  type: TIMER.REVERT_RESET
})