import React from 'react';
import { connect } from "react-redux";
import { useTransition, animated } from "react-spring";
import Selection from "./pages/Selection";
import Elevator from "./pages/Elevator";

import Header from "./components/Header";
const App = props => {

    const transitions = useTransition(props.floor, null, {
        from: { position: 'absolute', width: '100%', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 300 }
    })

    return (
        <React.Fragment>
            <Header />
            {transitions.map(({ item, key, props }) => (
                <div key={key}>
                    {item
                        ? <animated.div style={props}><Elevator /></animated.div>
                        : <animated.div style={props}><Selection /></animated.div>
                    }
                </div>
            ))}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    floor: state.floor.floor
})

export default connect(mapStateToProps)(App);