import { FLOOR } from "../actions/types";

const initialState = {
    floor: null,
    time: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FLOOR.RESET:
            return {
                ...state,
                floor: null,
                time: null,
            }
        case FLOOR.SET_FLOOR:
            return {
                ...state,
                floor: action.payload
            };
        case FLOOR.SET_TIME:
            return {
                ...state,
                time: action.payload
            }
        default:
            return state;
    }
}